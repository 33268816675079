import React from 'react'

const Features = () => {
  return (
    <section className="features-section pt-120 pb-120 section-md-bg" style={{backgroundImage: 'url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1693574953/lincfooter.png)'}}>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="section-header text-center">
          <span className="section-subtitle text-white">Our Amazing Features</span>
          <h2 className="section-title text-light">Investing For Everyone</h2>
          <p style={{color: '#f0fbf8'}}>We are worldwide investment company who are committed to the principle of revenue maximization and reduction of the financial risks at investing.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid">
    <div className="row">
      <div className="col-xl-4">
        <div className="feature-thumb anim-bounce">
          <img src="assets/images/elements/features.png" alt="image" />
        </div>
      </div>
      <div className="col-xl-4 offset-md-1 feature-item-wrapper">
        <div style={{backgroundColor: '#0d513f'}} className="feature-item wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
          <div className="icon">
            <div className="icon-inner">
              <img src="assets/images/icons/investment/1.svg" alt="icon" />
            </div>
          </div>
          <div className="content" >
            <h3 className="title text-white">Sign up in minutes</h3>
            <p style={{color: '#e4f1ed'}}>Open an investment account in minutes and get started by choosing any investment plan.</p>
            <a style={{color: '#e4f1ed'}} href="https://account.fx-globals.com">get strated</a>
          </div>
        </div>{/* feature-item end */}
        <div style={{backgroundColor: '#0d513f'}} className="feature-item wow fadeIn" data-wow-duration="2s" data-wow-delay="0.5s">
          <div className="icon">
            <div className="icon-inner">
              <img src="assets/images/icons/investment/2.svg" alt="icon" />
            </div>
          </div>
          <div className="content">
            <h3 className="title text-white">Investing Made Easy</h3>
            <p style={{color: '#e4f1ed'}}>Choose from the simple investment plans we have drafted out for you to maximize profits .We’ll take care of the rest!</p>
            <a style={{color: '#e4f1ed'}} href="https://account.fx-globals.com">read more</a>
          </div>
        </div>{/* feature-item end */}
        <div style={{backgroundColor: '#0d513f'}} className="feature-item wow fadeIn" data-wow-duration="2s" data-wow-delay="0.7s">
          <div className="icon">
            <div className="icon-inner">
              <img src="assets/images/icons/investment/3.svg" alt="icon" />
            </div>
          </div>
          <div className="content">
            <h3 className="title text-white">Build Your Porfolio</h3>
            <p style={{color: '#e4f1ed'}}>We’ll help you pick an investment strategy that reflects your interests,beliefs and goals.</p>
            <a style={{color: '#e4f1ed'}} href="https://account.fx-globals.com">explore our investing </a>
          </div>
        </div>{/* feature-item end */}
      </div>
    </div>
  </div>
</section>


  )
}

export default Features
