import React, {useEffect, useState} from 'react'
import apiClient from '../../request/http-common';

const GoldPlan = () => {
  const [plans, setPlans] = useState([]);
  const getAmount = (amount) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

  useEffect(() => {
    apiClient.get('/api/investment-plans')
        .then((response) => {
          if(response.data.status === 'success'){
             setPlans(response.data.gold_plans);        
          }
        });
  }, []);
  return (
    
<section className="investment-section pb-120" style={{backgroundColor: '#062019'}}>
  {plans.length > 0 &&
  <div className="container">
  <div className="row">
    <div className="col-lg-12">
      <div className="main-area" style={{backgroundColor: '#000705'}}>
        <h2 className="title text-white">Invest In Gold Mining And Earn Profits</h2>
        <div className="row mt-4 mb-none-30 investment-item-area">
        {plans.map(plan => (
              <div key={plan.id} className="col-lg-6 investment-item">
              <div className="investment-single mb-30">
                <div className="thumb  bg_img" style={{backgroundImage: `url(${plan.image? plan.image : 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1694428094/grid-one.webp'})`}}>
                </div>
                <div className="content" style={{backgroundColor: '#086047'}}>
                  <div className="left">
                    <h3 className="investment-title text-white">{plan.name}</h3>
                    <p style={{color: '#e4f1ed'}}> Earn {plan.total_earning}% ROI After {plan.duration}</p>
                    <div className="share-price" style={{backgroundColor: '#000705'}}><h3 className="price text-white">{plan.total_earning}%<small style={{color: '#f0fbf8'}}>/{plan.duration}</small></h3></div>

                    <div className="d-flex flex-wrap mt-5"> 
                      <div className="icon-item d-flex flex-wrap">
                        <div className="icon"><i className="icofont-ui-user-group" /></div>
                        <div className="content">
                          <span className="counter">{plan.bonus_per_downline}%</span>
                          <p style={{color: '#e4f1ed'}}>Referral Bonus</p>
                        </div>
                      </div>{/* icon-item end */}
                      <div className="icon-item d-flex flex-wrap">
                        <div className="icon"><i className="icofont-wall-clock" /></div>
                        <div className="content">
                          <span className="counter">{plan.duration}</span>
                          <p style={{color: '#e4f1ed'}}>Contract Duration</p>
                        </div>
                      </div>{/* icon-item end */}
                    </div>
                    <div className="shares-part" style={{backgroundColor: '#000705'}}>
                      <div className="share-item">
                        <span className="caption text-white">Hourly Rate</span>
                        <h4 className="amount">{getAmount(plan.amount_per_hour)}</h4>
                      </div>{/* share-item end */}
                      <div className="share-item">
                        <span className="caption text-white">Min Amount</span>
                        <h4 className="amount">{getAmount(plan.min_amount)}</h4>
                      </div>{/* share-item end */}
                      <div className="share-item">
                        <span className="caption text-white">Max Amount</span>
                        <h4 className="amount">{ parseInt(plan.amount_invested) >= 1000000 ? 'Unlimited': getAmount(plan.amount_invested)}</h4>
                      </div>{/* share-item end */}
                    </div>{/* shares-part end */}
                  </div>
                  <div className="right">
                    <div className="share-price"><h3 className="price">{plan.total_earning}<small>TOTAL ROI</small></h3></div>
                    <a href="https://account.fx-globals.com/" className="btn btn-secondary btn-hover text-small">invest now</a>
                    <a href="https://account.fx-globals.com/" className="add-wishlist"><i className="fa fa-heart" /> Add to wish list</a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</div>
  }
</section>

  )
}

export default GoldPlan
